import React, {
  Ref,
  ReactElement,
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import PulseFilter from 'components/pulse-filter/pulse-filter';
import PulseWithMore from 'components/pulse-with-more/pulse-with-more';
import PulseLabel from 'components/pulse-label/pulse-label';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { ButtonVariants, Colors, LabelSizes } from 'pulse-commons/types';
import { useResourcePlannerDispatch, useResourcePlannerState } from '../../context/resource-planner-context';
import { ResourcePlannerState } from '../../reducers/resource-planner-reducer';
import styles from './filter-flyout.module.scss';
import clsx from 'clsx';
import { Actions } from '../../reducers/resource-planner-reducer';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';
import invert from 'lodash/invert';
import debounce from 'lodash/debounce';
import { PulseFilterProps } from 'components/pulse-filter/pulse-filter-types';
import { Filters } from 'components/pulse-filter/slice';
import { useMediaQuery } from '@material-ui/core';

const SELECT_PAGE_SIZE = 25;
const SELECT_OFFICE_URL = `/v2/api/clients`;
const OFFICE_DATA_STRUCTURE = {
  searchKey: 'company',
  dataKey: 'data',
  isJsonApi: true,
  label: 'company',
  lastPage: 'meta.page.last-page',
  pageSize: SELECT_PAGE_SIZE,
  type: 'clients',
  value: 'clientid',
};

export const LAST_APPLIED_FILTERS = 'schedulerAppliedFilters';

type ScreenTypes = 'large-desktops' | 'extra-large-desktops' | 'other';
const MAX_WITH_MORE: Record<ScreenTypes, number> = {
  'large-desktops': 280,
  'extra-large-desktops': 380,
  other: 500,
};

interface FilterFlyProps {
  primaryClient?: {
    clientId: string | number;
    company: string;
  };
}

const FilterFlyout = ({ primaryClient }: FilterFlyProps, ref: Ref<any>): ReactElement => {
  const rpState: ResourcePlannerState = useResourcePlannerState();
  const rpDispatch = useResourcePlannerDispatch();
  const pulseFilterRef: Ref<any> = useRef(ref);
  const withMoreEl: Ref<any> = useRef(null);
  const initialMaxWidth = 500;
  const [maxWidth, setMaxWidth] = useState<number>(initialMaxWidth);
  const [isShowPtoOnly, setIsShowPtoOnly] = useState<boolean>(false);
  const isLargeDesktops = useMediaQuery('(max-width:1440px)');
  const isExtraLargeDesktops = useMediaQuery('(min-width:1440px)');
  /**
   * @todo what the hell is this for...
   */
  const RETURNED_TYPE = {
    assignedUser: 'assignedUsers',
    assignedUserOffice: 'userOffices',
    assignedUsers: 'assignedUsers',
    bookingStatus: 'bookingStatus',
    brand: 'brands',
    brandCat: 'brandcategories',
    brandcategories: 'brandcategories',
    brands: 'brands',
    departments: 'departments',
    jobOffices: 'jobOffices',
    jobs: 'jobs',
    reportedUser: 'requestor',
    requestor: 'requestor',
    roles: 'roles',
    userOffices: 'userOffices',
    usergroups: 'usergroups',
  };

  const handleSwitchChange = () => {
    setIsShowPtoOnly(!isShowPtoOnly);
  };

  const USER_GROUP_DATA_STRUCTURE = {
    dataKey: 'data',
    isJsonApi: true,
    label: 'group_name',
    lastPage: 'meta.page.last_page',
    pageSize: 10,
    searchKey: 'name',
    type: 'groups',
    value: 'group_id',
  };

  const allowFilters: PulseFilterProps['allowFilters'] = [
    {
      name: Filters.assignedUsers,
      PulseSelectBaseProps: {
        labelName: 'ASSIGNED USERS',
        url: `/v2/api/users?include=account-user.client&filter[pulse_active_status]=active&filter[bookable]=y`,
        placeholder: 'Search for Assigned Users...',
        minCharacters: 2,
        extraParams: {
          sort: 'name',
        },
      },
    },
    {
      name: Filters.customFilter,
      customFilter: {
        componentType: 'PulseSelectBase',
        filterName: 'usergroups',
      },
      PulseSelectBaseProps: {
        placeholder: 'Search for Assigned User Group...',
        labelName: 'ASSIGNED USER GROUP',
        url: '/v2/api/groups',
        dataStructure: USER_GROUP_DATA_STRUCTURE,
        extraParams: {
          sort: 'groupname',
        },
      },
    },
    {
      name: Filters.requestor,
      PulseSelectBaseProps: {
        labelName: 'REQUESTING USERS',
        url: `/v2/api/users?include=account-user.client&filter[pulse_active_status]=active`,
        placeholder: 'Search for Requesting Users...',
        minCharacters: 2,
        extraParams: {
          sort: 'name',
        },
      },
    },
    {
      name: Filters.jobs,
      PulseSelectBaseProps: {
        labelName: 'PROJECT',
        url: '/v2/api/jobs?sort=jobtitle',
        placeholder: 'Search for Project',
      },
    },
    {
      name: Filters.jobOffices,
      PulseSelectBaseProps: {
        labelName: 'PROJECT OFFICE',
        url: SELECT_OFFICE_URL,
        placeholder: 'Search for Project Office...',
        dataStructure: OFFICE_DATA_STRUCTURE,
        extraParams: {
          sort: 'company',
        },
      },
    },
    {
      name: Filters.userOffices,
      PulseSelectBaseProps: {
        labelName: 'ASSIGNED USER OFFICE',
        url: SELECT_OFFICE_URL,
        placeholder: 'Search for Assigned User Office...',
        dataStructure: OFFICE_DATA_STRUCTURE,
        extraParams: {
          sort: 'company',
        },
      },
    },
    {
      name: Filters.brands,
      PulseSelectBaseProps: {
        placeholder: 'Search for Brands',
        extraParams: { sort: 'title' },
      },
    },
    {
      name: Filters.brandcategories,
      PulseSelectBaseProps: {
        labelName: 'Master Clients',
        placeholder: 'Search for Brand Categories',
        extraParams: { sort: 'title' },
      },
    },
    {
      name: 'departments',
      PulseSelectBaseProps: {
        labelName: 'DEPARTMENTS',
        placeholder: 'Search for Departments',
        extraParams: {
          sort: 'name',
        },
      },
    },
    {
      name: Filters.roles,
      PulseSelectBaseProps: {
        labelName: 'ROLES',
        placeholder: 'Search for Roles',
      },
    },
    {
      name: 'bookingStatus',
      PulseSelectBaseProps: {
        labelName: 'BOOKING STATUS',
        placeholder: 'Search for Booking Status',
      },
    },
    {
      name: 'showPtoOnly',
      PulseSwitchProps: {
        labelLeft: 'YES',
        labelRight: 'NO',
        checked: !isShowPtoOnly,
        onChange: handleSwitchChange,
        classes: {
          container: styles['filter-flyout-switch__ctn'],
          labelLeft: styles['filter-flyout-switch__label'],
          labelRight: styles['filter-flyout-switch__label'],
        },
        SwitchProps: {
          classes: {
            root: styles['filter-flyout-switch__root'],
            input: styles['filter-flyout-switch__input'],
            track: styles['filter-flyout-switch__track'],
          },
        },
      },
    },
  ];

  /**
   * Format key from FilterFlyout to rpState.selectedFilters key format
   *
   * @todo type the params
   */
  const formatKeys = filters => {
    const formatObject = { ...filters };
    Object.keys(formatObject).forEach(keys => {
      if (!formatObject[keys]) {
        delete formatObject[keys];
      } else {
        const validKey = RETURNED_TYPE[keys];
        //replace filter by new filter with valid key
        validKey !== keys && delete Object.assign(formatObject, { [validKey]: formatObject[keys] })[keys];
      }
    });
    return formatObject;
  };

  /**
   * Format key from rpState.selectedFilters to FilterFlyout key format
   * @returns
   */
  const formatSelectedFilterKeys = () => {
    const formatSelectedFilter = [...(rpState.selectedFilters as PulseSelectOptionType[])];
    const invertReturnedType = invert(RETURNED_TYPE);
    formatSelectedFilter.forEach(filter => {
      const validType = invertReturnedType[filter.type];
      if (validType) {
        filter.value = filter.value.toString().replace(filter.type, '');
        filter.type = validType;
      }
    });
    return formatSelectedFilter;
  };

  /**
   * Transform Filters values to rpState.selectedFilters values
   */
  const setSelectedFilters = (filters: ResourcePlannerState['selectedFilters']) => {
    const selectedFilters: PulseSelectOptionType[] = [];
    if (!filters) {
      return selectedFilters;
    }
    Object.keys(filters).forEach(attributeKey => {
      if (filters[attributeKey]) {
        filters[attributeKey].forEach(item => {
          selectedFilters.push({
            type: attributeKey,
            value: `${item?.value}`,
            label: item?.label,
          });
        });
      }
    });
    return selectedFilters;
  };

  //Transform rpState.selectedFilters values to FilterFlyout values
  const formatSelectedFiltersToInitFilters = () => {
    const formatSelectedFilter = formatSelectedFilterKeys();
    const filterInitValues = {};
    formatSelectedFilter.forEach(filter => {
      if (!filterInitValues[filter.type]) {
        filterInitValues[filter.type] = [];
      }
      filterInitValues[filter.type].push(filter);
    });
    pulseFilterRef?.current &&
      Object.keys(filterInitValues).forEach(key => {
        pulseFilterRef?.current?.addElementToSelectedFilter(key, filterInitValues[key]);
      });
  };

  const [isFirstTimeUpdateAppliedFilters, setIsFirstTimeUpdateAppliedFilters] = useState<boolean>(true);

  useEffect(() => {
    if (rpState.selectedFilters?.length > 0 && isFirstTimeUpdateAppliedFilters) {
      const filterInitValues = {};
      rpState.selectedFilters?.forEach(filter => {
        if (!filterInitValues[filter.type]) {
          filterInitValues[filter.type] = [];
        }
        filterInitValues[filter.type].push(filter);
      });
      Object.keys(filterInitValues).forEach(key => {
        pulseFilterRef?.current?.editElementInAppliedFilter(key, filterInitValues[key]);
      });
    }
  }, [rpState.selectedFilters]);

  const handleOpenFilter = () => {
    setIsShowPtoOnly(rpState.viewMode === 'pto' ? true : false);
    pulseFilterRef?.current?.openForm();
    formatSelectedFiltersToInitFilters();
  };

  const setAppliedFilters = appliedFilters => {
    const keyFormattedFilters = formatKeys(appliedFilters);
    const filterValues: Array<string> = Object.values(appliedFilters);
    const isFilterEmpty = filterValues.every(value => !value || value?.length === 0);
    if (!isFilterEmpty) {
      /**
       * Remove current user
       */
      rpDispatch({
        type: Actions.setLoggedUserId,
        payload: {
          isCurrentUser: 0,
        },
      });
    }
    const selectedFilters = setSelectedFilters(keyFormattedFilters);
    selectedFilters.push({
      type: 'booking.filter.pto',
      value: +isShowPtoOnly,
      label: `booking.filter.pto${+isShowPtoOnly}`,
    });

    // set user's primary office to the filter if no office is applied
    const filterUserOffice = !!keyFormattedFilters.userOffices && !!keyFormattedFilters.userOffices.length;
    const filterJobOffice = !!keyFormattedFilters.jobOffices && !!keyFormattedFilters.jobOffices.length;
    if (!filterUserOffice && !filterJobOffice) {
      selectedFilters.push({
        type: 'userOffices',
        value: `userOffices${primaryClient?.clientId}`,
        label: primaryClient?.company ?? '',
      });
    }

    rpDispatch({
      type: Actions.setFilter,
      payload: {
        resourcePlannerDateRange: rpState.resourcePlannerDateRange,
        selectedFilters: selectedFilters,
      },
    });
  };

  /**
   * @todo type the params
   */
  const handleApplyFilters = appliedFilters => {
    if (isFirstTimeUpdateAppliedFilters) {
      setIsFirstTimeUpdateAppliedFilters(false);
    }

    localStorage.setItem(LAST_APPLIED_FILTERS, JSON.stringify(appliedFilters));
    setAppliedFilters(appliedFilters);
    pulseFilterRef?.current?.closeForm();
  };

  useImperativeHandle(ref, () => {
    return {
      openFilterFlyout: () => {
        setIsShowPtoOnly(rpState.viewMode === 'pto' ? true : false);
        pulseFilterRef?.current?.openForm();
        formatSelectedFiltersToInitFilters();
      },
      applyFilters: appliedFilters => setAppliedFilters(appliedFilters),
    };
  });

  /**
   * @todo type the params
   */
  const handleRemoveFilterTag = (filterName, filterValue) => {
    pulseFilterRef?.current && pulseFilterRef?.current?.removeElementInAppliedFilter(filterName, filterValue);
  };

  const handleResetCurrentUser = () => {
    rpDispatch({
      type: Actions.setLoggedUserId,
      payload: {
        isCurrentUser: 1,
      },
    });

    pulseFilterRef?.current?.closeForm();
  };

  const handleResize = useCallback(() => {
    debounce(() => {
      const parentNodeOfWithMore = withMoreEl?.current?.parentNode;
      if (parentNodeOfWithMore.clientWidth - 60 < initialMaxWidth) {
        setMaxWidth(parentNodeOfWithMore.clientWidth - 60);
      } else {
        setMaxWidth(initialMaxWidth);
      }
    }, 300);
  }, [withMoreEl?.current]);

  const typeScreen = useMemo((): ScreenTypes => {
    if (isLargeDesktops) {
      return 'large-desktops';
    }
    if (isExtraLargeDesktops) {
      return 'extra-large-desktops';
    }
    return 'other';
  }, [isLargeDesktops, isExtraLargeDesktops]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    setIsShowPtoOnly(rpState.viewMode === 'pto' ? true : false);
  }, [rpState.viewMode]);

  useEffect(() => {
    setMaxWidth(MAX_WITH_MORE[typeScreen]);
  }, [typeScreen]);

  return (
    <div className={styles['filter-flyout__ctn']}>
      <PulseWithMore
        classes={{
          root: styles['filter-flyout__with-more'],
          more: {
            label: {
              root: styles['filter-flyout__with-more-label'],
            },
          },
        }}
        HiddenChildProps={{
          classes: {
            root: styles['filter-flyout__with-more-hidden'],
          },
        }}
        ref={withMoreEl}
        maxWidth={maxWidth}
        childrenKey="label"
      >
        {rpState.selectedFilters &&
          rpState.selectedFilters.map(filter => {
            return (
              <PulseLabel
                classes={{
                  root: styles['filter-flyout__with-more-label'],
                }}
                actionButton
                actionButtonProps={{
                  classes: {
                    root: styles['filter-flyout__with-more-button'],
                    pulseIcon: {
                      icon: 'fal fa-times',
                    },
                  },
                  handleClick: () => {
                    // cannot remove the last user's office
                    if (filter?.type === Filters.userOffices || filter?.type === Filters.jobOffices) {
                      rpState?.selectedFilters?.filter(
                        item => item.type === Filters.userOffices || item.type === Filters.jobOffices,
                      ).length > 1 && handleRemoveFilterTag(filter?.type, filter);
                    } else {
                      handleRemoveFilterTag(filter?.type, filter);
                    }
                  },
                }}
                key={`${filter?.type}${filter?.value}`}
                color={Colors.default}
                size={LabelSizes.md}
                label={filter?.label}
                icon={false}
              />
            );
          })}
      </PulseWithMore>
      <PulseButtonBase
        classes={[styles['filter-flyout__btn']]}
        icon
        iconClasses={{
          icon: clsx(styles['filter-flyout__btn-icon'], 'fal fa-filter'),
        }}
        onClick={handleOpenFilter}
        label={''}
        variant={ButtonVariants.text}
      />
      <PulseFilter
        ref={pulseFilterRef}
        filterTitle={'SCHEDULER: FILTERS'}
        onApplyFilter={handleApplyFilters}
        allowFilters={allowFilters}
        onResetFilterCustom={handleResetCurrentUser}
      />
    </div>
  );
};

export default forwardRef(FilterFlyout);
