import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import PulseInput from 'components/pulse-input/pulse-input';
import { add, getHours, set } from 'date-fns';
import { useFormikContext } from 'formik';
import isNumber from 'lodash/isNumber';
import * as React from 'react';
import mergeRefs from 'react-merge-refs';
import styles from './booking-hours-input.module.scss';
const MIN_BOOKING_HOURS = 0.0;
const MAX_BOOKING_HOURS = 23.95;
const STEP = '0.05';
// backup when submit on another tab, ( the input unmount still keep value)
let backupInputHoursValue;

const BookingHoursInput = (
  {
    isSingleDay = false,
    totalDays,
  }: {
    isSingleDay: boolean;
    totalDays: number;
  },
  ref,
) => {
  const { values, errors, setFieldValue, setFieldError } = useFormikContext<Record<string, any>>();
  const { startDate, hoursPerDay, endDate } = values;
  const inputHoursRef = React.useRef<HTMLInputElement>();
  const isInputChanged = React.useRef<boolean>(false);
  const invalid = errors?.hoursPerDay;
  const hours = Number(hoursPerDay).toFixed(2);

  const validate = (): Promise<{
    normalizedInput: string;
    valueInput: string | number;
  }> => {
    return new Promise((resolve, reject) => {
      const inputValue = inputHoursRef.current?.value ?? backupInputHoursValue;
      // Replace comma with dot for decimal parsing
      const normalizedInput = inputValue?.replace(',', '.') ?? '';
      const valueInput = parseFloat(normalizedInput);

      // Check if the input is a string text (non-numeric)
      const isNonNumeric = isNaN(valueInput);
      if (isNonNumeric) {
        reject('Invalid input booking hours: Insert a valid number, please');
      }

      if (isNumber(valueInput)) {
        const isOverRange = valueInput <= MIN_BOOKING_HOURS || valueInput >= MAX_BOOKING_HOURS;
        if (isOverRange) {
          reject('Invalid input: Insert value higher than 0 and less than 24 hours');
        }
      }
      resolve({
        normalizedInput: Number(normalizedInput).toFixed(2),
        valueInput,
      });
    });
  };

  const calculatePickerTimeMappingWithHoursPerDay = hoursPerDayInput => {
    let startDateByInput = startDate;
    let endDateByInput = endDate;

    switch (Number(hoursPerDayInput)) {
      case 4:
        startDateByInput = set(startDate, {
          hours: 9,
          minutes: 0,
        });
        endDateByInput = set(startDate, {
          hours: 13,
          minutes: 0,
        });
        break;
      case 16:
        startDateByInput = set(startDate, {
          hours: 8,
          minutes: 0,
        });
        endDateByInput = set(startDate, {
          hours: 23,
          minutes: 59,
        });
        break;

      default:
        const baseHoursStartDate = 9;
        const popularWorkingTime = 24 - baseHoursStartDate;
        let hours = baseHoursStartDate;
        if (Number(hoursPerDayInput) > 14.99) {
          hours = baseHoursStartDate - (Number(hoursPerDayInput) - popularWorkingTime) - 1;
        }
        startDateByInput = set(startDate, {
          hours,
        });
        // default end date is plus start date with hours per day
        const minutesOfPercent = (Number(hoursPerDayInput) - Math.floor(Number(hoursPerDayInput))) * 60;
        endDateByInput = add(startDateByInput, {
          minutes: isNaN(minutesOfPercent) ? 0 : minutesOfPercent,
          hours: Number(hoursPerDayInput),
        });

        break;
    }

    setFieldValue('startDate', startDateByInput);
    setFieldValue('endDate', endDateByInput);
    isInputChanged.current = false;
  };

  const handleBlurInput = () => {
    if (inputHoursRef.current) {
      inputHoursRef.current.blur();
    }
  };

  const getInputValue = async () => {
    try {
      const data = await validate();
      return data;
    } catch (e: any) {
      console.warn(e);
      window?.utilities?.notification.danger(e);
      setFieldError('hoursPerDay', e);
    }
  };
  const updateInputAndState = async () => {
    handleBlurInput();
    const inputValue = await getInputValue();
    if (!inputValue) {
      return;
    }
    const { normalizedInput, valueInput } = inputValue;
    if (inputHoursRef.current) {
      if (isSingleDay) {
        calculatePickerTimeMappingWithHoursPerDay(valueInput);
      }
      setFieldValue('hoursPerDay', Number(valueInput));

      // set value to input dom
      inputHoursRef.current.value = normalizedInput;
    }
  };
  const handleClickField = () => {
    if (inputHoursRef.current) {
      inputHoursRef.current.focus();
      inputHoursRef.current.select();
    }
  };

  const handleClickAway = () => {
    if (isInputChanged.current) {
      updateInputAndState();
    }
  };

  const handleEnter = () => {
    updateInputAndState();
  };

  React.useEffect(() => {
    setFieldValue('getHoursPerDayAndTotalFromInputHours', async () => {
      const inputValue = await getInputValue();
      if (!inputValue) {
        return;
      }
      return {
        hoursPerDay: Number(inputValue.valueInput),
        totalHours: totalDays * Number(inputValue.valueInput),
      };
    });
  }, [totalDays]);

  React.useEffect(() => {
    backupInputHoursValue = inputHoursRef?.current?.value;
  }, [inputHoursRef?.current?.value]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles['booking-hours-input']} onClick={handleClickField}>
        <p className={styles['booking-hours-input__label']}>HRS/DAY</p>
        <PulseInput
          label=""
          classes={{
            root: clsx(
              styles['booking-hours-input__inputBase'],
              invalid && styles['booking-hours-input__inputBase--invalid'],
            ),
          }}
          InputBaseProps={{
            onKeyPress: e => {
              if (!inputHoursRef.current) {
                return;
              }
              if (e.key == 'Enter') {
                handleEnter();
              }
            },
            onChange: () => {
              isInputChanged.current = true;
              setFieldError('hoursPerDay', undefined);
            },
            fullWidth: false,
            defaultValue: hours,
            placeholder: 'HRS/DAY',
            inputProps: {
              ref: mergeRefs([inputHoursRef, ref]),
              min: MIN_BOOKING_HOURS,
              max: MAX_BOOKING_HOURS,
              maxlength: 5,
              step: STEP,
              pattern: '^\\d{1,2}([.,]\\d{1,2})?$',
            },
          }}
        />
      </div>
    </ClickAwayListener>
  );
};
export default React.forwardRef(BookingHoursInput);
